<template>
  <div>
    <template v-if="showMenu">
      <div class="cnt-title">
        <b @click="tabActive = 'root'">第一部分：基本信息 </b>
        <!-- <span v-if="tabActive !== 'root'">&nbsp;/&nbsp;{{ subTitle }}</span>
        <el-button
          size="mini"
          style="margin-left: 30px"
          type="success"
          v-if="tabActive !== 'root'"
          @click="tabActive = 'root'"
          >返回</el-button
        > -->
      </div>
      <el-card class="box-card">
        <div class="box-card-item">
          <div>一、封面</div>
          <div>
            <el-button type="primary" @click="handleEdit('fm')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestfm', '封面')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item pitem">
          <div class="title">二、成果基本信息表</div>
          <div class="box-card-item-c">
            <div>1、基本信息</div>
            <div>
              <el-button type="primary" @click="handleEdit('cbjbxx')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="xiazai('printTestcbjbxx', '成果基本信息表-基本信息')"
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>2、申报项目成果基本介绍</div>
            <div>
              <el-button type="primary" @click="handleEdit('xmcg')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai('printTestxmcg', '成果基本信息表-申报项目成果基本介绍')
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
        </div>

        <div class="box-card-item">
          <div>三、单位信息表</div>
          <div>
            <el-button type="primary" @click="handleEdit('dwxx')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestdwxx', '单位信息表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item pitem">
          <div class="title d-f-s">
            <div>四、完成单位表</div>
            <div>
              <el-button type="primary" @click="dwFlag = !dwFlag">{{
                dwFlag ? "关闭" : "查看"
              }}</el-button>
            </div>
          </div>
          <template v-if="dwFlag">
            <div
              class="box-card-item-c"
              v-for="(item, index) in 5"
              :key="index"
            >
              <div>{{ index + 1 }}、 第{{ zhList[index] }}完成单位</div>
              <div>
                <el-button type="primary" @click="handleEdit('wcdw', index)">{{
                  readOnly ? "查看" : "编辑"
                }}</el-button>
                <el-button
                  type="primary"
                  @click="xiazaiDw('printTestwcdw', index)"
                  v-if="!readOnly"
                  >下载</el-button
                >
              </div>
            </div>
          </template>
        </div>
        <div class="box-card-item pitem">
          <div class="title d-f-s">
            <div>五、完成人表</div>
            <div>
              <el-button type="primary" @click="wcrFlag = !wcrFlag">{{
                wcrFlag ? "关闭" : "查看"
              }}</el-button>
            </div>
          </div>
          <template v-if="wcrFlag">
            <div
              class="box-card-item-c"
              v-for="(item, index) in 10"
              :key="index"
            >
              <div>{{ index + 1 }}、 第{{ zhList[index] }}完成人</div>
              <div>
                <el-button type="primary" @click="handleEdit('wcr', index)">{{
                  readOnly ? "查看" : "编辑"
                }}</el-button>
                <el-button
                  type="primary"
                  @click="xiazaiWcr('printTestwcr', index)"
                  v-if="!readOnly"
                  >下载</el-button
                >
              </div>
            </div>
          </template>
        </div>
        <div class="box-card-item">
          <div>六、推荐意见表</div>
          <div>
            <el-button type="primary" @click="handleEdit('tjyj')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTesttjyj', '推荐意见表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
      </el-card>
    </template>
    <div
      class="print-warp"
      :class="editType == 'fm' && !showMenu ? 'show' : ''"
    >
      <div class="fengmian" id="printTestfm" ref="imageWrapper">
        <div>
          <div>2024年中国产学研合作促进会科技创新奖</div>
          <div>--创新成果奖</div>
          <div style="margin-top: 50px">申报材料</div>
        </div>
        <div style="width: 80%; padding: 20px 180px; box-sizing: border-box">
          <div>
            <div>申报项目名称:</div>
            <el-input
              v-if="iss"
              type="textarea"
              autosize
              placeholder=""
              v-model="forms.cover_name"
            >
            </el-input>
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
              "
              v-else
              v-html="forms.cover_name"
            ></div>
          </div>
          <div style="margin-top: 20px">
            <div>申报单位：</div>
            <input
              v-if="iss"
              type="text"
              v-model="forms.cover_unit"
              style="width: 100%"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
                line-height: 1.3;
              "
              v-else
              v-html="forms.cover_unit"
            ></div>
          </div>
          <div style="margin-top: 20px">
            <div>推荐单位/推荐专家：</div>
            <input
              v-if="iss"
              type="text"
              v-model="forms.cover_success"
              style="width: 100%"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
                line-height: 1.3;
              "
              v-else
              v-html="forms.cover_success"
            ></div>
          </div>
          <div style="margin-top: 20px">
            <div>申报时间:</div>
            <input
              v-if="iss"
              type="text"
              v-model="forms.cover_time"
              style="width: 100%"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
              "
              v-else
              v-html="forms.cover_time"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="feng_btn"
        style="display: flex; justify-content: center; margin-bottom: 30px"
      >
        <el-button type="primary" @click="handleBack">返回</el-button>
        <el-button
          type="primary"
          @click="handleSave(100, 'printTestfm', '封面')"
          v-if="!readOnly"
          >保存</el-button
        >
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'cbjbxx' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestcbjbxx"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div>
              <h3 class="zzzzzz">二、成果基本信息表</h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="text-align: center; width: 18%" colspan="1">
                    项目名称
                  </td>
                  <td colspan="7" height="80px">
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 15px 10px;
                      "
                    >
                      {{ forms.project_name }}
                    </div>
                    <!-- <input
                      type="text"
                      v-model="forms.project_name"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      disabled
                    /> -->
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="1"
                    style="text-align: center; width: 18%"
                    height="80px"
                  >
                    所属一级学科
                  </td>
                  <td colspan="3">
                    {{ iss ? "" : forms.first_level_discipline }}
                    <input
                      v-if="iss"
                      type="text"
                      v-model="forms.first_level_discipline"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                    />
                  </td>
                  <td colspan="1" style="text-align: center; width: 18%">
                    所属二级学科
                  </td>
                  <td colspan="3">
                    {{ iss ? "" : forms.second_level_discipline }}
                    <input
                      type="text"
                      v-if="iss"
                      v-model="forms.second_level_discipline"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="1"
                    style="text-align: center; width: 18%"
                    height="80px"
                  >
                    项目所属行业领域
                  </td>
                  <td colspan="7">
                    <div style="display: flex; flex-wrap: wrap; padding: 15px">
                      <div
                        :style="`
                          width:${option.label === '其他' ? '40%' : '20%'};
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          padding-left: 2px;
                          box-sizing: border-box;
                          line-height:2;`"
                        v-for="option in hyOptions"
                        :key="option.id"
                      >
                        <input
                          type="checkbox"
                          :id="option.id"
                          :value="option.value"
                          v-model="forms.domain"
                        />
                        <label :for="option.id">{{ option.label }}</label>
                        <template v-if="option.label === '其他'">
                          <input
                            type="text"
                            style="width: 80px; border-bottom: 1px solid #999"
                            v-model="forms.domain_other"
                            v-if="iss"
                          />
                          <div
                            style="
                              text-align: left;
                              border-bottom: 1px solid #999;
                              width: 80px;
                            "
                            v-else
                            v-html="forms.domain_other"
                          ></div>
                          <span>（备注）</span>
                        </template>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="1" style="text-align: center; width: 18%">
                    申报单位
                  </td>
                  <td colspan="7" height="80px">
                    <!-- <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 80%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      disabled
                      v-model="forms.declare_unit"
                    /> -->
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 15px 10px;
                      "
                      v-html="forms.declare_unit"
                    ></div>
                  </td>
                </tr>

                <tr>
                  <td colspan="1" style="text-align: center; width: 18%">
                    主要完成单位
                  </td>
                  <td colspan="7" height="80px">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 80%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      placeholder="申报单位为第一完成单位、主要完成单位共3-5家"
                      v-model="forms.accomplish_unit"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 15px 10px;
                      "
                      v-else
                      v-html="forms.accomplish_unit"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="1" style="text-align: center; width: 18%">
                    主要完成人
                  </td>
                  <td colspan="7" height="80px">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 80%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      placeholder="主要完成人共5-10人"
                      v-model="forms.accomplish_man"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 15px 10px;
                      "
                      v-else
                      v-html="forms.accomplish_man"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="1" style="text-align: center; width: 18%">
                    推荐单位
                    <div>/推荐专家</div>
                  </td>
                  <td colspan="7">
                    <!-- <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 80%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      disabled
                      v-model="forms.recommend_unit"
                    /> -->
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 15px 10px;
                      "
                      v-html="forms.recommend_unit"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="1" style="text-align: center; width: 18%">
                    任务来源
                  </td>
                  <td colspan="7" height="80px">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 80%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.source"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 15px 10px;
                      "
                      v-else
                      v-html="forms.source"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" height="200px">
                    <div>
                      <div style="text-align: left; box-sizing: border-box">
                        具体计划、基金名称和编号：
                      </div>
                      <el-input
                        v-if="iss"
                        type="textarea"
                        cols="70"
                        style="font-size: 30px; width: 100%"
                        rows="8"
                        v-model="forms.fund_number"
                      ></el-input>
                      <div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 15px 10px;
                          min-height: 300px;
                        "
                        v-else
                        v-html="forms.fund_number"
                      ></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    height="80px"
                    colspan="1"
                    style="text-align: center; width: 18%"
                  >
                    授权发明专利（项）
                  </td>
                  <td colspan="3">
                    <input
                      type="text"
                      v-model="forms.patent_invention"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                    />
                  </td>
                  <td colspan="1" style="text-align: center; width: 18%">
                    授权的其他知识产权（项）
                  </td>
                  <td colspan="3">
                    <input
                      type="text"
                      v-model="forms.authorization"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="1" style="text-align: center" height="80px">
                    项目起止时间
                  </td>
                  <td colspan="3" style="text-align: left; padding-left: 10px">
                    起始时间：{{ iss ? "" : forms.project_start }}
                    <input
                      v-if="iss"
                      type="text"
                      v-model="forms.project_start"
                      style="
                        width: 60%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                    />
                  </td>
                  <td colspan="4" style="text-align: left; padding-left: 10px">
                    完成时间：{{ iss ? "" : forms.project_end }}
                    <input
                      v-if="iss"
                      type="text"
                      v-model="forms.project_end"
                      style="
                        width: 60%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>

            <el-button
              type="primary"
              @click="
                handleSave(1, 'printTestcbjbxx', '成果基本信息表-基本信息')
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'xmcg' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestxmcg"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div>
              <h3 class="zzzzzz">二、成果基本信息表</h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="text-align: left">
                    <div style="font-weight: 600; padding-left: 10px">
                      申报项目成果基本介绍<span v-if="iss">（800字以内）</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; padding: 10px">
                    <div>
                      <div style="margin-bottom: 10px">1、科技创新：</div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder=" "
                        v-model="forms.innovation"
                        v-if="iss"
                      >
                      </el-input>
                      <pre
                        v-else
                      ><div style="text-align: left; min-height: 200px;white-space:pre-wrap;" v-html="forms.innovation"></div></pre>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; padding: 10px">
                    <div>
                      <div style="margin-bottom: 10px">2、客观评价：</div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 3 }"
                        placeholder=" "
                        v-model="forms.objective_evaluation"
                        v-if="iss"
                      >
                      </el-input>
                      <pre
                        v-else
                      ><div style="text-align: left; min-height: 150px;white-space:pre-wrap;" v-html="forms.objective_evaluation"></div></pre>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; padding: 10px">
                    <div>
                      <div style="margin-bottom: 10px">3、产学研合作情况：</div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 3 }"
                        placeholder=" "
                        v-model="forms.cooperate_situation"
                        v-if="iss"
                      >
                      </el-input>
                      <pre
                        v-else
                      ><div style="text-align: left; min-height: 150px;white-space:pre-wrap;" v-html="forms.cooperate_situation"></div></pre>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; padding: 10px">
                    <div>
                      <div style="margin-bottom: 10px">
                        4、项目专利、论文情况、客观评价：
                      </div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 5, maxRows: 5 }"
                        placeholder=" "
                        v-model="forms.project_paper_objective_evaluation"
                        v-if="iss"
                      >
                      </el-input>
                      <pre
                        v-else
                      ><div style="text-align: left; min-height: 250px;white-space:pre-wrap;" v-html="forms.project_paper_objective_evaluation"></div></pre>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; padding: 10px">
                    <div>
                      <div style="margin-bottom: 10px">
                        5、应用情况、近三年经济效益：
                      </div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 5, maxRows: 5 }"
                        placeholder=" "
                        v-model="forms.app_situation_three_years"
                        v-if="iss"
                      >
                      </el-input>
                      <pre
                        v-else
                      ><div style="text-align: left; min-height: 250px;white-space:pre-wrap;" v-html="forms.app_situation_three_years"></div></pre>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; padding: 10px">
                    <div>
                      <div style="margin-bottom: 10px">6、项目获奖情况：</div>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 5, maxRows: 5 }"
                        placeholder=" "
                        v-model="forms.project_award_situation"
                        v-if="iss"
                      >
                      </el-input>
                      <pre
                        v-else
                      ><div style="text-align: left; min-height: 250px;white-space:pre-wrap;" v-html="forms.project_award_situation"></div></pre>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(
                  2,
                  'printTestxmcg',
                  '成果基本信息表-申报项目成果基本介绍'
                )
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'dwxx' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestdwxx"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div>
              <h3 class="zzzzzz">三、申报单位信息表</h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="text-align: center">申报单位名称</td>
                  <td>
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.work_unit"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.work_unit"
                    ></div>
                  </td>
                  <td style="text-align: center">信用代码</td>
                  <td>
                    <input
                      v-if="iss"
                      type="text"
                      v-model="forms.credit_code"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                    />
                    <div class="input-text" v-else>{{ forms.credit_code }}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center" height="80px">单位负责人</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.user_name"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.user_name }}</div>
                  </td>
                  <td style="text-align: center">职 务</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.job"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.job }}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center" height="80px">单位地址</td>
                  <td>
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.unit_address"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.unit_address"
                    ></div>
                  </td>
                  <td style="text-align: center">邮 编</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.postcode"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.postcode }}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center" height="80px">
                    单位负责人座机
                  </td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.unit_tel"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.unit_tel }}</div>
                  </td>
                  <td style="text-align: center">单位负责人手机</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.unit_mobile"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.unit_mobile }}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center" height="80px">
                    联系人
                    <div>（联系部门）</div>
                  </td>
                  <td>
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.department"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.department"
                    ></div>
                  </td>
                  <td style="text-align: center">联系人手机</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.department_mobile"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.department_mobile }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center" height="80px">联系人座机</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.tel_mobile"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.tel_mobile }}</div>
                  </td>
                  <td style="text-align: center">电子邮箱</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.email"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.email }}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center" height="80px">单位职工人数</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.unit_num"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.unit_num }}</div>
                  </td>
                  <td style="text-align: center">
                    大专以上人员
                    <div>占职工比例</div>
                  </td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.unit_num_ratio"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.unit_num_ratio }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div class="zunshou">
                      <div class="zunshou-text">
                        本单位遵守《中国产学研合作促进会科技创新奖奖励办法》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        padding: 20px 200px 60px 150px;
                      "
                    >
                      <div>申报单位科研管理部门（盖章）</div>
                      <div>
                        <div style="text-align: left">申报单位（盖章）</div>
                        <div style="text-align: left; margin-top: 30px">
                          法人代表签名：
                        </div>
                        <div
                          style="
                            padding-right: 30px;
                            margin: 30px 0 0;
                            margin: 30px 0px 0px;
                            left: 120px;
                            position: relative;
                            display: flex;
                            align-items: end;
                          "
                        >
                          <input
                            type="text"
                            v-model="forms.signature_timeY"
                            style="
                              width: 60px;
                              text-align: center;
                              box-sizing: border-box;
                            "
                            v-if="iss"
                          />
                          <div
                            v-else
                            style="
                              width: 60px;
                              text-align: center;
                              box-sizing: border-box;
                            "
                          >
                            {{ forms.signature_timeY }}
                          </div>
                          <div>年</div>
                          <input
                            type="text"
                            v-model="forms.signature_timeM"
                            style="
                              width: 50px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                            v-if="iss"
                          />
                          <div
                            v-else
                            style="
                              width: 50px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                          >
                            {{ forms.signature_timeM }}
                          </div>
                          <div>月</div>
                          <input
                            type="text"
                            v-model="forms.signature_timeD"
                            style="
                              width: 50px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                            v-if="iss"
                          />
                          <div
                            v-else
                            style="
                              width: 50px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                          >
                            {{ forms.signature_timeD }}
                          </div>
                          <div>日</div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                        style="
                          display: flex;
                          justify-content: space-around;
                          width: 80%;
                          margin-top: 50px;
                        "
                      >
                        <div style="margin-right: 50px">
                          申报单位科研管理部门（盖章）
                        </div>
                        <div>申报单位（盖章）</div>
                      </div>
                      <div class="faren">
                        <div>
                          法人代表签名：
                          <input
                            type="text"
                            disabled
                            style="
                              width: 230px;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                          />
                        </div>
                        <div style="padding-right: 30px; margin: 20px 0 40px">
                          <input
                            type="text"
                            v-model="forms.signature_timeY"
                            style="
                              width: 60px;

                              box-sizing: border-box;
                            "
                          />年
                          <input
                            type="text"
                            v-model="forms.signature_timeM"
                            style="
                              width: 50px;

                              box-sizing: border-box;
                            "
                          />月
                          <input
                            type="text"
                            v-model="forms.signature_timeD"
                            style="
                              width: 50px;

                              box-sizing: border-box;
                            "
                          />日
                        </div>
                      </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>

            <el-button
              type="primary"
              @click="handleSave(3, 'printTestdwxx', '单位信息表')"
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'wcdw' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div style="font-size: 20px; margin-top: 20px">
            提示：第{{ zhList[curDwIndex] }}完成单位
          </div>
          <div
            id="printTestwcdw"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                四、完成单位情况表<span v-if="iss"
                  >（3-5家完成单位，每个单位一页）： （必填）</span
                >
              </h3>
              <div style="text-align: left; font-size: 20px">
                第{{ zhList[curDwIndex] }}完成单位
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="text-align: center" colspan="2" width="10%">
                    完成单位名称
                  </td>
                  <td colspan="2">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].company
                      "
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].company
                      "
                    ></div>
                  </td>
                  <td style="text-align: center" colspan="2" width="10%">
                    信用代码
                  </td>
                  <td colspan="3">
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].code
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_unit_data_${curDwIndex + 1}`].code }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="text-align: center"
                    colspan="2"
                    height="80px"
                    width="10%"
                  >
                    通讯地址
                  </td>
                  <td colspan="2">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].site
                      "
                    />
                    <div
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: left;
                      "
                      v-else
                      v-html="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].site
                      "
                    ></div>
                  </td>
                  <td style="text-align: center" colspan="2" width="10%">
                    邮 编
                  </td>
                  <td colspan="3">
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].postcode
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].postcode
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="text-align: center"
                    colspan="2"
                    width="10%"
                    height="80px"
                  >
                    联系人
                  </td>
                  <td colspan="2">
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].linkman
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].linkman
                      }}
                    </div>
                  </td>
                  <td style="text-align: center" colspan="2" width="10%">
                    手机
                  </td>
                  <td colspan="3">
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].phone
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].phone
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="text-align: center"
                    colspan="2"
                    width="10%"
                    height="80px"
                  >
                    电话
                  </td>
                  <td colspan="2">
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].phones
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].phones
                      }}
                    </div>
                  </td>
                  <td style="text-align: center" colspan="2" width="10%">
                    电子信箱
                  </td>
                  <td colspan="3">
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_unit_data_${curDwIndex + 1}`].mail
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_unit_data_${curDwIndex + 1}`].mail }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" style="padding: 10px" height="200px">
                    <div style="margin-bottom: 10px; text-align: left">
                      科技创新、推广应用情况<span v-if="iss"
                        >：（限800字）</span
                      >
                    </div>
                    <div>
                      <el-input
                        v-if="iss"
                        type="textarea"
                        cols="70"
                        v-model="
                          forms[`accomplish_unit_data_${curDwIndex + 1}`].case
                        "
                        style="font-size: 30px; width: 100%"
                        rows="8"
                        maxlength="800"
                        show-word-limit
                      ></el-input>
                      <div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 15px 10px;
                          min-height: 400px;
                        "
                        v-else
                        v-html="
                          forms[`accomplish_unit_data_${curDwIndex + 1}`].case
                        "
                      ></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="8">
                    <div class="zunshou">
                      <div class="zunshou-text">
                        本单位遵守《中国产学研合作促进会科技创新奖奖励办法》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                      </div>
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          flex-direction: column;
                          align-items: flex-end;
                          padding-right: 200px;
                          box-sizing: border-box;
                          margin-top: 50px;
                        "
                      >
                        <div style="margin-right: 90px">单位（盖章）</div>
                        <div style="margin: 50px 0 30px">
                          <input
                            type="text"
                            v-model="
                              forms[`accomplish_unit_data_${curDwIndex + 1}`]
                                .dateY
                            "
                            style="
                              width: 60px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                          />年
                          <input
                            type="text"
                            v-model="
                              forms[`accomplish_unit_data_${curDwIndex + 1}`]
                                .dateM
                            "
                            style="
                              width: 50px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                          />月
                          <input
                            type="text"
                            v-model="
                              forms[`accomplish_unit_data_${curDwIndex + 1}`]
                                .dateD
                            "
                            style="
                              width: 50px;
                              box-sizing: border-box;
                              text-align: center;
                            "
                          />日
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>

            <el-button
              type="primary"
              @click="
                handleSave(
                  'dw',
                  'printTestwcdw',
                  `完成单位表-第${zhList[curDwIndex]}完成单位`
                )
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'wcr' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div style="font-size: 20px; margin-top: 20px">
            提示：第{{ zhList[curpIndex] }}完成人
          </div>
          <div
            id="printTestwcr"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                五、完成人情况表<span v-if="iss"
                  >：（5-10个完成人、所有完成人均须填写）</span
                >
              </h3>
              <div style="text-align: left; font-size: 20px">
                第{{ zhList[curpIndex] }}完成人
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td height="80px" style="text-align: center; width: 15%">
                    姓名
                  </td>
                  <td>
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].name
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_man_data_${curpIndex + 1}`].name }}
                    </div>
                  </td>
                  <td style="text-align: center; width: 10%">性别</td>
                  <td>
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].sex
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_man_data_${curpIndex + 1}`].sex }}
                    </div>
                  </td>
                  <td style="text-align: center; width: 10%">排名</td>
                  <td>
                    <input
                      type="text"
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].ranking
                      "
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_man_data_${curpIndex + 1}`].ranking
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td height="80px" style="text-align: center; width: 16%">
                    工作单位
                  </td>
                  <td colspan="5">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].unit
                      "
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="
                        forms[`accomplish_man_data_${curpIndex + 1}`].unit
                      "
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="1"
                    height="80px"
                    style="text-align: center; width: 16%"
                  >
                    职务
                  </td>
                  <td height="80px">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].job
                      "
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms[`accomplish_man_data_${curpIndex + 1}`].job"
                    ></div>
                  </td>
                  <td style="text-align: center">职称</td>
                  <td>
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].technical
                      "
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="
                        forms[`accomplish_man_data_${curpIndex + 1}`].technical
                      "
                    ></div>
                  </td>
                  <td style="text-align: center">身份证</td>
                  <td>
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].identity
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_man_data_${curpIndex + 1}`].identity
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center; width: 16%">通信地址</td>
                  <td colspan="3">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].site
                      "
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="
                        forms[`accomplish_man_data_${curpIndex + 1}`].site
                      "
                    ></div>
                  </td>
                  <td style="text-align: center">邮编</td>
                  <td>
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].postcode
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{
                        forms[`accomplish_man_data_${curpIndex + 1}`].postcode
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center; width: 16%">电 话</td>
                  <td height="80px" colspan="3">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].phone
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_man_data_${curpIndex + 1}`].phone }}
                    </div>
                  </td>
                  <td style="text-align: center">手机</td>
                  <td>
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].phones
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_man_data_${curpIndex + 1}`].phones }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center; width: 16%">电子信箱</td>
                  <td height="80px" colspan="3">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="
                        forms[`accomplish_man_data_${curpIndex + 1}`].mail
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms[`accomplish_man_data_${curpIndex + 1}`].mail }}
                    </div>
                  </td>
                  <td style="text-align: center">签字</td>
                  <td>
                    <!-- <input type="text" /> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(
                  'wcr',
                  'printTestwcr',
                  `完成人表-第${zhList[curpIndex]}完成人`
                )
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'tjyj' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTesttjyj"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div>
              <h3 class="zzzzzz">
                六、推荐意见表
                <span v-if="iss">（推荐单位与推荐专家二选一）</span>
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 16%">
                    推荐单位
                    <div>/ 推荐专家</div>
                  </td>
                  <td style="width: 34%">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.recommend"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.recommend"
                    ></div>
                  </td>
                  <td style="width: 13%">
                    信用代码
                    <div>/ 身份证号</div>
                  </td>
                  <td style="width: 37%">
                    <input
                      type="text"
                      v-model="forms.recommend_credit_code"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_credit_code }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>通信地址</td>
                  <td>
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.mailing_address"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.mailing_address"
                    ></div>
                  </td>
                  <td>邮编</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.recommend_code"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_code }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    推荐单位联系人
                    <div>/推荐专家</div>
                  </td>
                  <td>
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.recommend_name"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.recommend_name"
                    ></div>
                  </td>
                  <td>联系电话</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.recommend_tel"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_tel }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>电子邮箱</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.recommend_email"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_email }}
                    </div>
                  </td>
                  <td>手机</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.recommend_mobile"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_mobile }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="4" height="320px">
                    <div style="width: 95%; text-align: left; margin: 15px">
                      推荐单位介绍/专家介绍<span v-if="iss"
                        >(单位、职务职称、研究领域等):</span
                      >
                    </div>
                    <div>
                      <el-input
                        v-if="iss"
                        type="textarea"
                        v-model="forms.recommend_zhuanjia"
                        cols="40"
                        rows="8"
                        style="font-size: 30px"
                        placeholder="提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式"
                      ></el-input>

                      <div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 0 10px;
                          min-height: 270px;
                        "
                        v-else
                        v-html="forms.recommend_zhuanjia"
                      ></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div style="width: 95%; text-align: left; margin: 15px">
                      推荐意见:
                    </div>
                    <el-input
                      v-if="iss"
                      type="textarea"
                      v-model="forms.recommend_opinion"
                      cols="40"
                      style="font-size: 24px"
                      rows="6"
                    ></el-input>
                    <div
                      style="
                        text-align: left;
                        padding: 0 10px;
                        box-sizing: border-box;
                        min-height: 270px;
                      "
                      v-else
                      v-html="forms.recommend_opinion"
                    ></div>
                    <div style="margin: 30px 20px 20px 0; text-align: right">
                      推荐专家签字/推荐单位（盖章）
                    </div>
                    <div style="text-align: right">
                      <div
                        style="
                          display: flex;
                          margin: 0 120px 80px 0;
                          flex-direction: row-reverse;
                        "
                      >
                        <span>日</span
                        ><span style="display: block; margin: 0 30px">月</span
                        ><span>年</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="handleSave(19, 'printTesttjyj', '推荐意见表')"
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  upload_file,
  user_chile_details,
  innovation_success,
  innovation_select,
  award_find,
  upload_pdf,
  user_file,
  download_file,
  file_download,
  del_file,
} from "../../axios/api";
export default {
  props: {
    fromData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fromData: {
      handler: function (newUser) {
        this.forms = JSON.parse(JSON.stringify(newUser));
        this.forms.project_name = this.forms.cover_name;
        this.forms.declare_unit = this.forms.cover_unit;
        this.forms.recommend_unit = this.forms.cover_success;
      },
      deep: true, // 设置 deep 选项为 true，实现深度监听
    },
  },
  mounted() {
    this.forms = JSON.parse(JSON.stringify(this.fromData));
    this.forms.project_name = this.forms.cover_name;
    this.forms.declare_unit = this.forms.cover_unit;
    this.forms.recommend_unit = this.forms.cover_success;
    if (this.readOnly) {
      this.iss = false;
    }
  },
  data() {
    return {
      dwFlag: false,
      wcrFlag: false,
      zhList: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
      tabActive: "root", // 根目录
      subTitle: "", // 副标题
      iss: true,
      showMenu: true, // 是否展示目录
      editType: "", // 编辑类型
      forms: {
        accomplish_unit_data_1: {},
        accomplish_man_data_1: {},
      },
      hyOptions: [
        { id: 1, value: "生物医药", label: "生物医药" },
        { id: 2, value: "电子信息", label: "电子信息" },
        { id: 3, value: "农业", label: "农业" },
        { id: 4, value: "机械", label: "机械" },
        { id: 5, value: "新能源", label: "新能源" },
        { id: 6, value: "资源环境", label: "资源环境" },
        { id: 7, value: "电力", label: "电力" },
        { id: 8, value: "轻工", label: "轻工" },
        { id: 9, value: "交通", label: "交通" },
        { id: 10, value: "水利", label: "水利" },
        { id: 11, value: "土木", label: "土木" },
        { id: 12, value: "化工", label: "化工" },
        { id: 13, value: "材料", label: "材料" },
        { id: 14, value: "其他", label: "其他" },
      ],
      curDwIndex: 0, // 当前第几个单位
      curpIndex: 0,
    };
  },
  methods: {
    changeMenu(type, name) {
      console.log(type, name);
      this.tabActive = type;
      this.subTitle = name;
    },
    handleEdit(type, index) {
      this.showMenu = false;
      this.editType = type;
      if (type === "wcdw") {
        this.curDwIndex = index;
      } else if (type === "wcr") {
        this.curpIndex = index;
      }
      if (type === "cbjbxx") {
        this.forms.project_name = this.forms.cover_name;
        this.forms.declare_unit = this.forms.cover_unit;
        this.forms.recommend_unit = this.forms.cover_success;
      }
    },
    handleBack() {
      this.showMenu = true;
      this.editType = "";
      this.forms = { ...this.fromData };
    },
    handleSave(type, idName, typeName) {
      this.iss = !this.iss;
      let reqParams = {
        token: sessionStorage.getItem("Token"),
        id: this.forms.id,
        type: type + "",
      };
      if (type == 100) {
        reqParams.cover_name = this.forms.cover_name;
        reqParams.cover_unit = this.forms.cover_name;
        reqParams.cover_success = this.forms.cover_success;
        reqParams.cover_time = this.forms.cover_time;
      } else if (type == 1) {
        reqParams.project_name = this.forms.project_name;
        reqParams.first_level_discipline = this.forms.first_level_discipline;
        reqParams.second_level_discipline = this.forms.second_level_discipline;
        reqParams.domain = this.forms.domain;
        reqParams.domain_other = this.forms.domain_other;
        reqParams.declare_unit = this.forms.declare_unit;
        reqParams.accomplish_unit = this.forms.accomplish_unit;
        reqParams.accomplish_man = this.forms.accomplish_man;
        reqParams.recommend_unit = this.forms.recommend_unit;
        reqParams.source = this.forms.source;
        reqParams.fund_number = this.forms.fund_number;
        reqParams.patent_invention = this.forms.patent_invention;
        reqParams.authorization = this.forms.authorization;
        reqParams.project_start = this.forms.project_start;
        reqParams.project_end = this.forms.project_end;
      } else if (type == 2) {
        reqParams.innovation = this.forms.innovation;
        reqParams.objective_evaluation = this.forms.objective_evaluation;
        reqParams.cooperate_situation = this.forms.cooperate_situation;
        reqParams.project_paper_objective_evaluation =
          this.forms.project_paper_objective_evaluation;
        reqParams.app_situation_three_years =
          this.forms.app_situation_three_years;
        reqParams.project_award_situation = this.forms.project_award_situation;
      } else if (type == 3) {
        reqParams.work_unit = this.forms.work_unit;
        reqParams.credit_code = this.forms.credit_code;
        reqParams.user_name = this.forms.user_name;
        reqParams.job = this.forms.job;
        reqParams.unit_address = this.forms.unit_address;
        reqParams.postcode = this.forms.postcode;
        reqParams.unit_tel = this.forms.unit_tel;
        reqParams.unit_mobile = this.forms.unit_mobile;
        reqParams.department = this.forms.department;
        reqParams.department_mobile = this.forms.department_mobile;
        reqParams.tel_mobile = this.forms.tel_mobile;
        reqParams.email = this.forms.email;
        reqParams.unit_num = this.forms.unit_num;
        reqParams.unit_num_ratio = this.forms.unit_num_ratio;
        reqParams.signature_time =
          (this.forms.signature_timeY || "") +
          "-" +
          (this.forms.signature_timeM || "") +
          "-" +
          (this.forms.signature_timeD || "");
      } else if (type === "dw") {
        if (this.curDwIndex === 0) {
          reqParams.type = 4;
          reqParams.accomplish_unit_data_1 = JSON.stringify(
            this.forms.accomplish_unit_data_1
          );
        } else if (this.curDwIndex === 1) {
          reqParams.type = 5;
          reqParams.accomplish_unit_data_2 = JSON.stringify(
            this.forms.accomplish_unit_data_2
          );
        } else if (this.curDwIndex === 2) {
          reqParams.type = 6;
          reqParams.accomplish_unit_data_3 = JSON.stringify(
            this.forms.accomplish_unit_data_3
          );
        } else if (this.curDwIndex === 3) {
          reqParams.type = 7;
          reqParams.accomplish_unit_data_4 = JSON.stringify(
            this.forms.accomplish_unit_data_4
          );
        } else if (this.curDwIndex === 4) {
          reqParams.type = 8;
          reqParams.accomplish_unit_data_5 = JSON.stringify(
            this.forms.accomplish_unit_data_5
          );
        }
      } else if (type === "wcr") {
        if (this.curpIndex === 0) {
          reqParams.type = 9;
          reqParams.accomplish_man_data_1 = JSON.stringify(
            this.forms.accomplish_man_data_1
          );
        } else if (this.curpIndex === 1) {
          reqParams.type = 10;
          reqParams.accomplish_man_data_2 = JSON.stringify(
            this.forms.accomplish_man_data_2
          );
        } else if (this.curpIndex === 2) {
          reqParams.type = 11;
          reqParams.accomplish_man_data_3 = JSON.stringify(
            this.forms.accomplish_man_data_4
          );
        } else if (this.curpIndex === 3) {
          reqParams.type = 12;
          reqParams.accomplish_man_data_4 = JSON.stringify(
            this.forms.accomplish_man_data_4
          );
        } else if (this.curpIndex === 4) {
          reqParams.type = 13;
          reqParams.accomplish_man_data_5 = JSON.stringify(
            this.forms.accomplish_man_data_5
          );
        } else if (this.curpIndex === 5) {
          reqParams.type = 14;
          reqParams.accomplish_man_data_6 = JSON.stringify(
            this.forms.accomplish_man_data_6
          );
        } else if (this.curpIndex === 6) {
          reqParams.type = 15;
          reqParams.accomplish_man_data_7 = JSON.stringify(
            this.forms.accomplish_man_data_7
          );
        } else if (this.curpIndex === 7) {
          reqParams.type = 16;
          reqParams.accomplish_man_data_8 = JSON.stringify(
            this.forms.accomplish_man_data_8
          );
        } else if (this.curpIndex === 8) {
          reqParams.type = 17;
          reqParams.accomplish_man_data_9 = JSON.stringify(
            this.forms.accomplish_man_data_9
          );
        } else if (this.curpIndex === 9) {
          reqParams.type = 18;
          reqParams.accomplish_man_data_10 = JSON.stringify(
            this.forms.accomplish_man_data_10
          );
        } else {
        }
      } else if (type == 19) {
        reqParams.recommend = this.forms.recommend;
        reqParams.recommend_credit_code = this.forms.recommend_credit_code;
        reqParams.mailing_address = this.forms.mailing_address;
        reqParams.recommend_code = this.forms.recommend_code;
        reqParams.recommend_name = this.forms.recommend_name;
        reqParams.recommend_tel = this.forms.recommend_tel;
        reqParams.recommend_email = this.forms.recommend_email;
        reqParams.recommend_mobile = this.forms.recommend_mobile;
        reqParams.recommend_zhuanjia = this.forms.recommend_zhuanjia;
        reqParams.recommend_opinion = this.forms.recommend_opinion;
      }
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新成果奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);

          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form)
            .then((res) => {
              let params = {
                user_split_id: this.forms.id, //申报记录ID2
                path_url: res.data.url, //url
                type: reqParams.type,
                token: sessionStorage.getItem("Token"),
                type_name: typeName,
              };
              user_file(params).then((res) => {
                if (res.data.msg == "成功") {
                  innovation_success(reqParams).then((res) => {
                    if (res.data.code == 200) {
                      this.$message({
                        message: "保存成功",
                        type: "success",
                      });
                      this.$emit("update:fromData", this.forms);
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  });
                } else {
                  alert("保存失败，请重新下一步");
                }
              });
            })
            .catch(() => {});
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiazaiDw(idName, index) {
      this.curDwIndex = index;
      let fileName = `完成单位表-第${this.zhList[index]}完成单位`;
      this.iss = !this.iss;
      const loading = this.$loading({
        lock: true,
        text: "玩命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          loading.close();
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://2024cs.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiazaiWcr(idName, index) {
      this.curpIndex = index;
      let fileName = `完成人表-第${this.zhList[index]}完成人`;
      this.iss = !this.iss;
      const loading = this.$loading({
        lock: true,
        text: "玩命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          loading.close();
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://2024cs.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiazai(idName, fileName) {
      this.iss = !this.iss;
      const loading = this.$loading({
        lock: true,
        text: "玩命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          loading.close();
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://2024cs.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.cnt-title {
  font-size: 30px;
  margin: 80px 5% 80px 5%;
  display: flex;
  align-items: center;
  b {
    cursor: pointer;
  }
  span {
    color: #999;
  }
}
.box-card {
  width: 65%;
  margin: auto;
  margin-bottom: 100px;
  ::v-deep .el-card__body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .box-card-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    font-size: 26px;
    font-weight: 500;
    &.pitem {
      flex-direction: column;
      width: 100%;
      .title {
        width: 100%;
      }
    }
    .d-f-s {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .box-card-item-c {
      margin-left: 50px;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dbdbdb;
      width: calc(100% - 50px);
      font-weight: 500;

      &:nth-child(1) {
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
.cnt-warp {
  overflow: hidden;
  width: 90%;
  margin: auto;
  .cnt-item {
    width: 18%;
    margin-right: 2.5%;
    margin-bottom: 2%;
    float: left;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .cnt-item-top {
      width: 100%;
      padding-top: 133.33%;
      height: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .cnt-item-bottom {
      padding: 30px;
      display: flex;
      justify-content: space-around;
    }
  }
}
.cnt-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  width: 90%;
  color: #ffe400;
  letter-spacing: 2px;
}
</style>
<style scoped>
.print-warp {
  position: absolute;
  left: -10000px;
  top: -10000px;
  width: 100%;
}
.print-warp.show {
  position: relative;
  top: 0;
  left: 0;
}

.fengmian {
  width: 1200px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  /* padding-bottom: 100px; */
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  flex: 0 0 240px;
  font-size: 22px;
}
.ccc td {
  text-align: center;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 30px;
  border: 0px;
  border-bottom: 1px solid #999;
}
.feng_btn {
  margin: 10px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}
/* .feng_btn > div {
  margin: 10px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
  position: relative;
}
.shenMain .bh {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
    border-color: #000;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 150px;
  text-align: center;
  font-size: 24px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  width: 90px;
  min-height: 32px !important;
  line-height: 32px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 24px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zunshou {
  display: flex;
  flex-direction: column;
  line-height: 1.8;
}
.qz-warp {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
}
.dwwarp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dwwarp input {
  width: auto;
}
input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
}
.table-textarea {
  width: 100%;
  outline: none;
  box-sizing: border-box;
}
.faren {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 60px;
}
.faren > div {
  margin-left: 50px;
}
.d-f-s {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-text {
  padding: 0 10px;
  text-align: left;
  box-sizing: border-box;
}
</style>
