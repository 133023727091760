<template>
  <div>
    <template v-if="showMenu">
      <div class="cnt-title">
        <b @click="tabActive = 'root'">第一部分：基本信息 </b>
        <!-- <span v-if="tabActive !== 'root'">&nbsp;/&nbsp;{{ subTitle }}</span>
        <el-button
          size="mini"
          style="margin-left: 30px"
          type="success"
          v-if="tabActive !== 'root'"
          @click="tabActive = 'root'"
          >返回</el-button
        > -->
      </div>
      <el-card class="box-card">
        <div class="box-card-item">
          <div>一、封面</div>
          <div>
            <el-button type="primary" @click="handleEdit('fm')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTest', '封面')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item">
          <div>二、基本信息表</div>
          <div>
            <el-button type="primary" @click="handleEdit('jbxx')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestjbxx', '基本信息表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>

        <div class="box-card-item">
          <div>三、推荐意见表</div>
          <div>
            <el-button type="primary" @click="handleEdit('tjyj')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTesttjyj', '推荐意见表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
      </el-card>
      <!-- <div class="cnt-warp">
        <div class="cnt-item">
          <div class="cnt-item-top">
            <img src="@/assets/static/img/itembg.jpg" alt="" />
            <div class="cnt-text">封面</div>
          </div>
          <div class="cnt-item-bottom">
            <el-button type="primary" @click="handleEdit('fm')">{{readOnly?'查看':'编辑'}}</el-button>
            <el-button type="primary" @click="xiaxiadayin"  v-if="!readOnly">下载</el-button>
          </div>
        </div>
        <div class="cnt-item">
          <div class="cnt-item-top">
            <img src="@/assets/static/img/itembg.jpg" alt="" />
            <div class="cnt-text">基本信息</div>
          </div>
          <div class="cnt-item-bottom">
            <el-button type="primary" @click="handleEdit('jbxx')"
              >{{readOnly?'查看':'编辑'}}</el-button
            >
            <el-button type="primary" @click="xiaxiajbxx"  v-if="!readOnly">下载</el-button>
          </div>
        </div>
        <div class="cnt-item">
          <div class="cnt-item-top">
            <img src="@/assets/static/img/itembg.jpg" alt="" />
            <div class="cnt-text">推荐意见</div>
          </div>
          <div class="cnt-item-bottom">
            <el-button type="primary" @click="handleEdit('tjyj')"
              >{{readOnly?'查看':'编辑'}}</el-button
            >
            <el-button type="primary" @click="xiaxiatjyj"  v-if="!readOnly">下载</el-button>
          </div>
        </div>
      </div> -->
    </template>
    <div
      class="print-warp"
      :class="editType == 'fm' && !showMenu ? 'show' : ''"
    >
      <div class="fengmiannew" id="printTest" ref="imageWrapper">
        <div>
          <div>2024年中国产学研合作促进会科技创新奖</div>
          <div>--创新人物奖</div>
          <div style="margin-top: 30px">申报表</div>
        </div>
        <div style="width: 80%; padding: 0 180px; box-sizing: border-box">
          <div>
            <div>申报人姓名：</div>
            <input
              type="text"
              style="width: 100%"
              v-model="forms.cover_name"
              v-if="iss"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
              "
              v-else
              v-html="forms.cover_name"
            ></div>
          </div>
          <div>
            <div>申报单位：</div>
            <input
              type="text"
              style="width: 100%"
              v-if="iss"
              v-model="forms.cover_unit"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
              "
              v-else
              v-html="forms.cover_unit"
            ></div>
          </div>
          <div>
            <div>推荐单位/推荐专家：</div>
            <input
              type="text"
              style="width: 100%"
              v-if="iss"
              v-model="forms.cover_success"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
              "
              v-else
              v-html="forms.cover_success"
            ></div>
          </div>

          <div>
            <div>申报时间：</div>
            <input
              type="text"
              style="width: 100%"
              v-if="iss"
              v-model="forms.cover_time"
            />
            <div
              style="
                text-align: left;
                border-bottom: 1px solid #999;
                width: 100%;
              "
              v-else
              v-html="forms.cover_time"
            ></div>
          </div>
        </div>
      </div>
      <div class="feng_btn">
        <el-button @click="handleBack" type="primary">返回</el-button>
        <el-button
          @click="handleSaveFm"
          type="primary"
          v-if="!readOnly"
          :loading="btnLoading"
          >保存</el-button
        >
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'jbxx' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestjbxx"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div>
              <h3 class="zzzzzz">二、申报人基本信息表</h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 100px">姓名</td>
                  <td>
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.user_name"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.user_name }}</div>
                  </td>
                  <td>性别</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.sex"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.sex }}</div>
                  </td>

                  <td>民族</td>
                  <td>
                    <input
                      type="text"
                      v-model="forms.nation"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.nation }}</div>
                  </td>
                  <td>籍贯</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.native_place"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.native_place }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>出生年月</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.birthday"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.birthday }}</div>
                  </td>
                  <td>政治面貌</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.politics_name"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.politics_name }}
                    </div>
                  </td>
                  <td>最高学历</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.education"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.education }}</div>
                  </td>
                </tr>
                <tr>
                  <td>身份证号</td>
                  <td colspan="5">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.id_car"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.id_car }}</div>
                  </td>
                  <td>手机</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.mobile"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.mobile }}</div>
                  </td>
                </tr>
                <tr>
                  <td>所属学科</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.discipline"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.discipline }}</div>
                  </td>
                  <td>
                    专业技术
                    <div>职称</div>
                  </td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.specialty"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.specialty }}</div>
                  </td>
                  <td>申报人邮箱</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.email"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>{{ forms.email }}</div>
                  </td>
                </tr>
                <tr>
                  <td>工作单位及行政职务</td>
                  <td colspan="8">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.job"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.job"
                    ></div>
                  </td>
                  <!-- <td>单位性质</td>
                                <td colspan="2">
                                    <input
                                    type="text"
                                    style="
                                        width: 100%;
                                        padding: 0 10px;
                                        box-sizing: border-box;
                                    "
                                    v-model="forms.nature_of_unit"
                                    />
                                </td> -->
                </tr>
                <tr>
                  <td>学科领域</td>
                  <td colspan="5">
                    <div style="display: flex; flex-wrap: wrap">
                      <div
                        :style="`
                          width:${option.label === '其他' ? '40%' : '20%'};
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          padding-left: 2px;
                          box-sizing: border-box;
                          line-height:2;`"
                        v-for="option in xkOptions"
                        :key="option.id"
                      >
                        <input
                          type="checkbox"
                          :id="option.id"
                          :value="option.value"
                          v-model="forms.discipline_area"
                        />
                        <label :for="option.id">{{ option.label }}</label>
                        <template v-if="option.label === '其他'">
                          <input
                            type="text"
                            style="width: 80px; border-bottom: 1px solid #999"
                            v-model="forms.discipline_area_other"
                            v-if="iss"
                          />
                          <div
                            style="
                              text-align: left;
                              border-bottom: 1px solid #999;
                              width: 80px;
                            "
                            v-else
                            v-html="forms.discipline_area_other"
                          ></div>
                          <span>（备注）</span>
                        </template>
                      </div>
                    </div>
                  </td>
                  <td>单位性质</td>
                  <td colspan="2">
                    <div class="dwwarp">
                      <div
                        v-for="option in dwOptions"
                        :key="option.id"
                        style="
                          display: flex;
                          align-items: center;
                          width: 100%;
                          padding-left: 40px;
                          line-height: 2;
                        "
                      >
                        <input
                          type="checkbox"
                          :id="option.id"
                          :value="option.value"
                          v-model="forms.nature_of_unit"
                        />
                        <label :for="option.id">{{ option.label }}</label>
                        <template v-if="option.label === '其他'">
                          <input
                            type="text"
                            style="width: 45%; border-bottom: 1px solid #999"
                            v-model="forms.nature_of_unit_other"
                            v-if="iss"
                          />
                          <div
                            style="
                              text-align: left;
                              border-bottom: 1px solid #999;
                              width: 45%;
                            "
                            v-else
                            v-html="forms.nature_of_unit_other"
                          ></div>
                        </template>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>联系部门</td>
                  <td colspan="5">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        padding: 0 10px;
                        height: 100%;
                        box-sizing: border-box;
                        font-size: 28px;
                      "
                      v-model="forms.department"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.department"
                    ></div>
                  </td>
                  <td>联系人</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.department_user_name"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.department_user_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>通讯地址</td>
                  <td colspan="5">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        padding: 0 10px;
                        height: 100%;
                        box-sizing: border-box;
                        font-size: 28px;
                      "
                      v-model="forms.unit_address"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.unit_address"
                    ></div>
                  </td>
                  <td>联系人手机</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.department_mobile"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.department_mobile }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>联系人邮箱</td>
                  <td colspan="5">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.department_email"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.department_email }}
                    </div>
                  </td>
                  <td>
                    联系人电话
                    <div>(加区号)</div>
                  </td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.department_distinguish_mobile"
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.department_distinguish_mobile }}
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td>传真</td>
                  <td colspan="5">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.fax"
                    />
                  </td>
                  <td>联系人手机</td>
                  <td colspan="2">
                    <input
                      type="text"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-model="forms.mobile_phone"
                    />
                  </td>
                </tr> -->
                <tr>
                  <td>申<br />报<br />人<br />意<br />见</td>
                  <td colspan="8">
                    <div class="zunshou">
                      <div class="zunshou-text">
                        本人遵守《中国产学研合作促进会科技创新奖奖励办法》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                      </div>
                    </div>
                    <div class="qz-warp1" style="margin-top: 60px">
                      <div class="text-left">申报人签字：</div>
                      <div>
                        <input
                          type="text"
                          style="
                            width: 50px;
                            padding: 0;
                            text-align: left;
                            box-sizing: border-box;
                          "
                          v-model="forms.ryear"
                        />年
                        <input
                          type="text"
                          style="
                            width: 30px;
                            padding: 0;
                            text-align: left;
                            box-sizing: border-box;
                          "
                          v-model="forms.rMonth"
                        />月
                        <input
                          type="text"
                          style="
                            width: 30px;
                            padding: 0;
                            text-align: left;
                            box-sizing: border-box;
                          "
                          v-model="forms.rDay"
                        />日
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>申<br />报<br />单<br />位<br />意<br />见</td>
                  <td colspan="8">
                    <div class="biaoge">
                      <div class="zunshou">
                        <div class="zunshou-text">
                          本单位遵守《中国产学研合作促进会科技创新奖奖励办法》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                        </div>
                      </div>
                      <div class="qz-warp1" style="margin-top: 60px">
                        <div class="text-left">
                          申报单位科研管理部门（盖章）
                        </div>
                        <div>
                          <div>
                            <input
                              type="text"
                              style="width: 50px; padding: 0"
                              disabled
                            />申报单位（盖章）
                          </div>
                          <div
                            style="
                              text-align: left;
                              padding-top: 40px;
                              padding-bottom: 30px;
                            "
                          >
                            <input
                              type="text"
                              style="
                                width: 50px;
                                padding: 0;
                                text-align: left;
                                box-sizing: border-box;
                              "
                              v-model="forms.dyear"
                            />年
                            <input
                              type="text"
                              style="
                                width: 30px;
                                padding: 0;
                                text-align: left;
                                box-sizing: border-box;
                              "
                              v-model="forms.dMonth"
                            />月
                            <input
                              type="text"
                              style="
                                width: 30px;
                                padding: 0;
                                text-align: left;
                                box-sizing: border-box;
                              "
                              v-model="forms.dDay"
                            />日
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn" style="position: relative; z-index: 3">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button type="primary" v-if="!readOnly" @click="jbxxSave"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'tjyj' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTesttjyj"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div>
              <h3 class="zzzzzz">
                三、推荐意见表
                <span v-if="iss">（推荐单位与推荐专家二选一）</span>
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td height="100px" style="width: 13%">
                    推荐单位
                    <div>/ 推荐专家</div>
                  </td>
                  <td style="width: 37%">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;
                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.recommend"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.recommend"
                    ></div>
                  </td>
                  <td style="width: 13%">
                    信用代码
                    <div>/ 身份证号码</div>
                  </td>
                  <td style="width: 37%">
                    <input
                      type="text"
                      v-model="forms.recommend_credit_code"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_credit_code }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td height="100px" style="width: 13%">通信地址</td>
                  <td style="width: 37%">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.mailing_address"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.mailing_address"
                    ></div>
                  </td>
                  <td style="width: 13%">邮编</td>
                  <td style="width: 37%">
                    <input
                      type="text"
                      v-model="forms.recommend_code"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_code }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td height="100px" style="width: 13%">
                    推荐单位联系人/ 推荐专家
                  </td>
                  <td style="width: 37%">
                    <textarea
                      v-if="iss"
                      type="text"
                      style="
                        border: 1px solid #000;
                        width: 100%;

                        height: 90%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        font-size: 28px;
                      "
                      v-model="forms.recommend_name"
                    />
                    <div
                      style="
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 10px;
                      "
                      v-else
                      v-html="forms.recommend_name"
                    ></div>
                  </td>
                  <td style="width: 13%">联系电话</td>
                  <td style="width: 37%">
                    <input
                      type="text"
                      v-model="forms.recommend_tel"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_tel }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td height="100px" style="width: 13%">电子邮箱</td>
                  <td style="width: 37%">
                    <input
                      type="text"
                      v-model="forms.recommend_email"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_email }}
                    </div>
                  </td>
                  <td style="width: 13%">手机</td>
                  <td style="width: 37%">
                    <input
                      type="text"
                      v-model="forms.recommend_mobile"
                      style="
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                      "
                      v-if="iss"
                    />
                    <div class="input-text" v-else>
                      {{ forms.recommend_mobile }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="4" height="400px">
                    <div style="height: 400px">
                      <div style="width: 85%; text-align: left; margin: 15px">
                        推荐单位介绍 / 专家介绍（单位、职务职称、研究领域等）
                      </div>
                      <div>
                        <el-input
                          v-if="iss"
                          type="textarea"
                          v-model="forms.recommend_zhuanjia"
                          cols="40"
                          rows="8"
                          style="font-size: 30px"
                          placeholder="提示：推荐专家应为院士或者同行业领域二级教授、表格中须填写专家本人联系方式"
                        ></el-input>

                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 0 10px;
                          "
                          v-else
                          v-html="forms.recommend_zhuanjia"
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" height="530px">
                    <div style="height: 530px">
                      <div style="width: 95%; text-align: left; margin: 15px">
                        推荐意见:
                      </div>
                      <el-input
                        v-if="iss"
                        type="textarea"
                        v-model="forms.recommend_opinion"
                        cols="40"
                        style="font-size: 24px"
                        rows="6"
                      ></el-input>
                      <div
                        style="
                          text-align: left;
                          padding: 0 10px;
                          box-sizing: border-box;
                          border: none;
                          min-height: 280px;
                        "
                        v-else
                        v-html="forms.recommend_opinion"
                      ></div>
                      <div style="margin: 30px 20px 20px 0; text-align: right">
                        推荐专家签字/推荐单位（盖章）
                      </div>
                      <div style="text-align: right">
                        <div
                          style="
                            display: flex;
                            margin: 0 120px 80px 0;
                            flex-direction: row-reverse;
                          "
                        >
                          <span>日</span
                          ><span style="display: block; margin: 0 30px">月</span
                          ><span>年</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button @click="handleBack" type="primary">返回</el-button>
            <el-button @click="tjyjSave" v-if="!readOnly" type="primary"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { innovation_will, user_file, upload_pdf } from "../../axios/api";
export default {
  props: {
    fromData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fromData: {
      handler: function (newUser) {
        this.forms = JSON.parse(JSON.stringify(newUser));
      },
      deep: true, // 设置 deep 选项为 true，实现深度监听
    },
  },
  mounted() {
    this.forms = JSON.parse(JSON.stringify(this.fromData));
    if (this.readOnly) {
      this.iss = false;
    }
  },
  data() {
    return {
      tabActive: "root", // 根目录
      showMenu: true, // 是否展示目录
      editType: "", // 编辑类型
      forms: {},
      discipline_area: [], //学科领域
      nature_of_unit: [], // 单位性质
      iss: true,
      btnLoading: false,
      xkOptions: [
        { id: 1, value: "生物医药", label: "生物医药" },
        { id: 2, value: "电子信息", label: "电子信息" },
        { id: 3, value: "农业", label: "农业" },
        { id: 4, value: "机械", label: "机械" },
        { id: 5, value: "能源", label: "能源" },
        { id: 6, value: "资源环境", label: "资源环境" },
        { id: 7, value: "电力", label: "电力" },
        { id: 8, value: "轻工", label: "轻工" },
        { id: 9, value: "交通", label: "交通" },
        { id: 10, value: "水利", label: "水利" },
        { id: 11, value: "土木", label: "土木" },
        { id: 12, value: "化工", label: "化工" },
        { id: 13, value: "材料", label: "材料" },
        { id: 14, value: "其他", label: "其他" },
      ],
      dwOptions: [
        { id: 20, value: "高等院校", label: "高等院校" },
        { id: 21, value: "科研院所", label: "科研院所" },
        { id: 22, value: "国有企业", label: "国有企业" },
        { id: 23, value: "民营企业", label: "民营企业" },
        { id: 24, value: "外资企业", label: "外资企业" },
        { id: 25, value: "政府机关", label: "政府机关" },
        { id: 26, value: "其他", label: "其他" },
      ],
    };
  },
  methods: {
    handleEdit(type) {
      this.showMenu = false;
      this.editType = type;
    },
    handleBack() {
      this.showMenu = true;
      this.editType = "";
      this.forms = { ...this.fromData };
    },
    handleSaveFm() {
      if (this.btnLoading) return;
      this.btnLoading = true;
      this.iss = !this.iss;
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新人物奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.forms.id, //申报记录ID2
              path_url: res.data.url, //url
              type: "100",
              token: sessionStorage.getItem("Token"),
              type_name: "封面",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                let reqParams = {
                  token: sessionStorage.getItem("Token"),
                  id: this.forms.id,
                  type: "100",
                  cover_unit: this.forms.cover_unit,
                  cover_name: this.forms.cover_name,
                  cover_time: this.forms.cover_time,
                  cover_success: this.forms.cover_success,
                };
                innovation_will(reqParams)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.$message({
                        message: "保存成功",
                        type: "success",
                      });
                      this.$emit("update:fromData", this.forms);
                      // this.num = 1;
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  })
                  .finally(() => {
                    this.btnLoading = false;
                  });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    jbxxSave() {
      if (this.btnLoading) return;
      this.btnLoading = true;
      this.iss = !this.iss;
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新人物奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.forms.id, //申报记录ID2
              path_url: res.data.url, //url
              type: "1",
              token: sessionStorage.getItem("Token"),
              type_name: "基本信息表",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                let reqParams = {
                  token: sessionStorage.getItem("Token"),
                  id: this.forms.id,
                  type: "1",
                  user_name: this.forms.user_name,
                  sex: this.forms.sex,
                  nation: this.forms.nation,
                  native_place: this.forms.native_place,
                  birthday: this.forms.birthday,
                  politics_name: this.forms.politics_name,
                  education: this.forms.education,
                  id_car: this.forms.id_car,
                  mobile: this.forms.mobile,
                  discipline: this.forms.discipline,
                  specialty: this.forms.specialty,
                  email: this.forms.email,
                  job: this.forms.job,
                  department: this.forms.department,
                  department_user_name: this.forms.department_user_name,
                  unit_address: this.forms.unit_address,
                  department_mobile: this.forms.department_mobile,
                  department_email: this.forms.department_email,
                  department_distinguish_mobile:
                    this.forms.department_distinguish_mobile,
                  applicant_date:
                    (this.forms.ryear || "") +
                    "-" +
                    (this.forms.rMonth || "") +
                    "-" +
                    (this.forms.rDay || ""),
                  applicant_department_date:
                    (this.forms.dyear || "") +
                    "-" +
                    (this.forms.dMonth || "") +
                    "-" +
                    (this.forms.dDay || ""),
                  discipline_area: this.forms.discipline_area.length
                    ? JSON.stringify(this.forms.discipline_area)
                    : "",
                  discipline_area_other: this.forms.discipline_area_other,
                  nature_of_unit: this.forms.nature_of_unit.length
                    ? JSON.stringify(this.forms.nature_of_unit)
                    : "",
                  nature_of_unit_other: this.forms.nature_of_unit_other,
                };
                innovation_will(reqParams)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.$message({
                        message: "保存成功",
                        type: "success",
                      });
                      this.$emit("update:fromData", this.forms);
                      // this.num = 1;
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  })
                  .finally(() => {
                    this.btnLoading = false;
                  });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    tjyjSave() {
      if (this.btnLoading) return;
      this.btnLoading = true;
      this.iss = !this.iss;
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新人物奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.forms.id, //申报记录ID2
              path_url: res.data.url, //url
              type: "2",
              token: sessionStorage.getItem("Token"),
              type_name: "推荐意见表",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                let reqParams = {
                  token: sessionStorage.getItem("Token"),
                  id: this.forms.id,
                  type: "2",
                  recommend: this.forms.recommend,
                  recommend_credit_code: this.forms.recommend_credit_code,
                  mailing_address: this.forms.mailing_address,
                  recommend_code: this.forms.recommend_code,
                  recommend_name: this.forms.recommend_name,
                  recommend_tel: this.forms.recommend_tel,
                  recommend_email: this.forms.recommend_email,
                  recommend_mobile: this.forms.recommend_mobile,
                  recommend_zhuanjia: this.forms.recommend_zhuanjia,
                  recommend_opinion: this.forms.recommend_opinion,
                };
                innovation_will(reqParams)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.$message({
                        message: "保存成功",
                        type: "success",
                      });
                      this.$emit("update:fromData", this.forms);
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  })
                  .finally(() => {
                    this.btnLoading = false;
                  });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiazai(idName, fileName) {
      this.iss = !this.iss;
      const loading = this.$loading({
        lock: true,
        text: "玩命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新人物奖",
          true
        );
        setTimeout(() => {
          loading.close();
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://2024cs.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            );
            //  window.open(
            //   `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            // );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    // xiaxiatjyj() {
    //   this.iss = !this.iss;
    //   setTimeout(() => {
    //     this.$downloadPDF.downloadPDF(
    //       document.querySelector("#printTesttjyj"),
    //       "创新成果奖申报",
    //       true
    //     );
    //     setTimeout(() => {
    //       console.log(this.$myfile);
    //       const form = new FormData();
    //       form.append("file", this.$myfile);
    //       form.append("token", sessionStorage.getItem("Token"));
    //       upload_pdf(form).then((res) => {
    //         window.open(
    //           `https://cjhpj.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=基本信息`
    //         );
    //       });
    //     }, 1000);

    //     this.iss = !this.iss;
    //   }, 1000);
    // },
    //  xiaxiadayin() {
    //   this.iss = !this.iss;
    //   setTimeout(() => {
    //     this.$downloadPDF.downloadPDF(
    //       document.querySelector("#printTest"),
    //       "创新成果奖申报",
    //       true
    //     );
    //     setTimeout(() => {
    //       console.log(this.$myfile);
    //       const form = new FormData();
    //       form.append("file", this.$myfile);
    //       form.append("token", sessionStorage.getItem("Token"));
    //       upload_pdf(form).then((res) => {
    //         window.open(
    //           `https://cjhpj.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=首页`
    //         );
    //       });
    //     }, 1000);

    //     this.iss = !this.iss;
    //   }, 1000);
    // },
  },
};
</script>
<style lang="less" scoped>
.cnt-title {
  font-size: 30px;
  margin: 80px 5% 80px 5%;
  display: flex;
  align-items: center;
  b {
    cursor: pointer;
  }
  span {
    color: #999;
  }
}
.box-card {
  width: 68%;
  margin: auto;
  margin-bottom: 100px;
  ::v-deep .el-card__body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .box-card-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    font-size: 26px;
    font-weight: 500;
    &.pitem {
      flex-direction: column;
      width: 100%;
      .title {
        width: 100%;
      }
    }
    .box-card-item-c {
      margin-left: 50px;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dbdbdb;
      width: calc(100% - 50px);
      font-weight: 500;

      &:nth-child(1) {
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
.cnt-warp {
  overflow: hidden;
  width: 90%;
  margin: auto;
  .cnt-item {
    width: 18%;
    margin-right: 2.5%;
    margin-bottom: 2%;
    float: left;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .cnt-item-top {
      width: 100%;
      padding-top: 133.33%;
      height: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .cnt-item-bottom {
      padding: 30px;
      display: flex;
      justify-content: space-around;
    }
  }
}
.cnt-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  width: 90%;
  color: #ffe400;
  letter-spacing: 2px;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
}
</style>
<style scoped>
.print-warp {
  position: absolute;
  left: -10000px;
  top: -10000px;
  width: 100%;
}
.print-warp.show {
  position: relative;
  top: 0;
  left: 0;
}
.fengmiannew {
  width: 1200px;
  height: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmiannew > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmiannew > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmiannew > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.fengmiannew > div:nth-child(2) > div > div:nth-child(1) {
  width: 350px;
  font-size: 22px;
}
.fengmiannew > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 24px;
  border: 0px;
  border-bottom: 1px solid #999;
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
  position: relative;
}
.shenMain .bh {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
  border-color: #000;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 150px;
  text-align: center;
  font-size: 24px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  width: 90px;
  min-height: 32px !important;
  line-height: 32px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 24px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zunshou {
  display: flex;
  flex-direction: column;
  line-height: 1.8;
}
.qz-warp1 {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: left;
}
.text-left {
  padding-left: 100px;
}
.qz-warp1 > div {
  flex: 1;
}
.dwwarp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dwwarp input {
  width: auto;
}
input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
}
.input-text {
  padding: 0 10px;
  text-align: left;
  box-sizing: border-box;
}
</style>
